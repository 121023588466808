import { Nav, Navbar } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';

import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import omnipodDiscover from '../../assets/images/discover-logo.svg';
import { IBrowserInfo, ICallback, IControl, IHome, IMenuEntry, ITranslator } from '../../types';
import MenuHelper from '../../helpers/MenuHelper';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import { anI18NextLibActionCreator } from '../../store/home';

const menuLabelToShow = (entry: IMenuEntry, label: string) => {
    return (
        <span className={clsx(styleGuide.H3BlackLeft, styleGeneral.menuItem)}>
            <div className={styleGeneral.iconFrame}>
                {entry?.icon && <img src={entry.icon} alt={entry?.label} className={styleGeneral.icon} />}
            </div>
            <div className={styleGeneral.labelFrame}>{label}</div>
        </span>
    );
};
const renderBannerNotIsProd = (translate: ITranslator, notIsProd: boolean) =>
    notIsProd && <div className={styleGeneral.nonProdUse}>{translate('nonProdUse')}</div>;
const renderBannerIsUnsupportedBrowser = (
    translate: ITranslator,
    notIsProd: boolean,
    isUnsupportedBrowser: boolean
) => {
    if (isUnsupportedBrowser) {
        const unsupportedBrowserClass = styleGeneral.unsupportedBrowser;
        return <div className={unsupportedBrowserClass}>{translate('unsupportedBrowser')}</div>;
    }

    return null;
};

function Menu({
    home,
    menuEntries,
    isMenuOpen,
    menuOpenCloseCb,
    translate,
}: {
    home: IHome;
    menuEntries: IMenuEntry[];
    isMenuOpen: boolean;
    menuOpenCloseCb: ICallback<void>;
    translate: ITranslator;
}) {
    const dispatch = useDispatch();
    const [orientation, setOrientation] = useState<'ltr' | 'rtl'>('ltr');

    const control = home.control ?? ({} as IControl);
    const browserInfo = control?.browserInfo ?? ({} as IBrowserInfo);
    const notIsProd = !control.isProd;
    const isUnsupportedBrowser = !browserInfo.supported;

    const setLanguage = (language: string) => {
        dispatch(anI18NextLibActionCreator({ anI18Nextlib: AnI18NextLibHelper.loadLang(language) }));
    };

    useEffect(() => {
        if (orientation === 'rtl') {
            document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        } else {
            document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
        }
    }, [orientation]);

    return (
        <div className={styleGeneral.main}>
            {renderBannerNotIsProd(translate, notIsProd)}
            {renderBannerIsUnsupportedBrowser(translate, notIsProd, isUnsupportedBrowser)}
            <Navbar
                className={clsx(styleGeneral.navbarBox, 'navbar', 'navbar-light', 'bg-light')}
                collapseOnSelect
                expand={false}
                bg="light"
                variant="light"
            >
                <Tooltip title={translate('goToHomeScreen')}>
                    <Navbar.Brand
                        href="/"
                        className={clsx(styleGeneral.navbarBrand, styleGeneral.banner)}
                        onClick={() => menuOpenCloseCb({ isMenuOpen: false, menuEntry: MenuHelper.MenuEntryHome })}
                    >
                        <img className={styleGeneral.navbarLogo} src={omnipodDiscover} alt="Omnipod Discover™" />
                    </Navbar.Brand>
                </Tooltip>
                {home.isMenuVisible && (
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        data-testid="menu-button"
                        className={clsx(styleGeneral.navbarHamburger, styleGeneral.layerTopmost)}
                        onClick={() => menuOpenCloseCb({ isMenuOpen: !isMenuOpen })}
                    >
                        <div className={styleGeneral.menu}>
                            <div className={clsx(styleGeneral.bar1, isMenuOpen && styleGeneral.bar1Change)}></div>
                            <div className={clsx(styleGeneral.bar2, isMenuOpen && styleGeneral.bar2Change)}></div>
                            <div className={clsx(styleGeneral.bar3, isMenuOpen && styleGeneral.bar3Change)}></div>
                        </div>
                    </Navbar.Toggle>
                )}
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className={clsx(styleGeneral.layerTopmost, styleGeneral.scrollable, styleGeneral.menuFrame)}
                >
                    <Nav className="mr-auto" data-testid="main-menu">
                        {menuEntries
                            ?.filter((menuEntry) => menuEntry.label?.length > 0)
                            ?.map((menuEntry, idx: number) => (
                                <span key={`navSpan${idx}`}>
                                    {menuEntry?.preSeparate && idx > 0 && (
                                        <span key={`navSpanT${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                    )}
                                    <Nav.Link
                                        data-testid={menuEntry?.label?.replace(' ', '_')}
                                        key={`navRow${idx}`}
                                        eventKey={`navLink${idx}`}
                                        href={menuEntry.path}
                                        onClick={() => menuOpenCloseCb({ isMenuOpen: false, menuEntry: menuEntry })}
                                    >
                                        {menuLabelToShow(menuEntry, translate(`menu.${menuEntry.key}`))}
                                    </Nav.Link>
                                    {menuEntry?.postSeparate && (
                                        <span key={`navSpanB${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                    )}
                                </span>
                            ))}
                    </Nav>
                    <div>
                        <h5>Language</h5>
                        <button type="button" onClick={() => setLanguage('ar')}>
                            ar
                        </button>
                        <button type="button" onClick={() => setLanguage('de')}>
                            de
                        </button>
                        <button type="button" onClick={() => setLanguage('en')}>
                            en
                        </button>
                        <button type="button" onClick={() => setLanguage('fi')}>
                            fi
                        </button>
                        <button type="button" onClick={() => setLanguage('he')}>
                            he
                        </button>
                        <button type="button" onClick={() => setLanguage('ja')}>
                            ja
                        </button>
                        <button type="button" onClick={() => setLanguage('pt')}>
                            pt
                        </button>
                        <button type="button" onClick={() => setLanguage('zh')}>
                            ch
                        </button>
                    </div>
                    <div>
                        <h4>Orientation</h4>
                        <button type="button" onClick={() => setOrientation(orientation === 'ltr' ? 'rtl' : 'ltr')}>
                            {orientation === 'ltr' ? 'RTL' : 'LTR'}
                        </button>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu;
